.countdownTimer {
  color: #1fa2ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0;
  border: 1px solid #1fa2ff;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #363f44;
}
.timeforquestion {
  color: #1fa2ff;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  font-size: 14px;
  margin: 0;
  border: 1px solid #1fa2ff;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #363f44;
}
