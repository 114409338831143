.quote-container {
  padding: 60px 0;
  background-color: #37474f;
  text-align: center;
}

.quote-container h4 {
  display: inline-block;
  position: relative;
  text-align: center;
}

.quote-container h4::before {
  content: "\“";
  position: absolute;
  top: -26px;
  left: -15px;
  font-size: 1.3em;
  opacity: 0.4;
}
.quote-container h4::after {
  content: "\”";
  position: absolute;
  top: 26px;
  right: -15px;
  font-size: 1.3em;
  opacity: 0.4;
}

.quote-container span {
  display: block;
  text-align: center;
  margin-top: 26px;
}
