html {
  scroll-behavior: smooth;
  background-color: #2a3136;
}
@font-face {
  font-family: titleFont;
  src: url("./fonts/Monda-Regular.ttf");
}
.landing {
  height: 600px;
  width: 100%;
}
.content {
  text-align: center;
  padding: 10 0 20 0;
  margin-left: 10%;
  margin-right: 10%;
}
.stepguide {
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
}
.stepbar {
  text-align: center;
  padding: 10 0 20 0;
}
@media (max-width: 1024px) {
  .content {
    text-align: center;
    padding: 10 0 20 0;
    margin-left: 1%;
    margin-right: 1%;
  }
}
.evaluation {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px 30px 20px 30px;
  text-transform: uppercase;
}
@media (max-width: 1440px) {
  .evaluation {
    margin-left: 1%;
    margin-right: 1%;
  }
}
@media (max-width: 500px) {
  .evaluation {
    margin-left: 0%;
    margin-right: 0%;
    padding: 20px 10px 20px 10px;
  }
}
